export default {

    title               : 'Privacy Policy',

    privacyPolicy     : 
    `
    <h1>Privacy Policy</h1>

    <p>Last updated: December 1, 2019</p>
    
    <p>LunareSoft IT Consulting Services Inc. ("us", "we", or "our") operates the MyBrainMate software (the "Service").</p>
    
    <p><strong>Personal Information Collect, Use, Disclosure and Consent</strong></p>
    
    <p>Personal Information may include your name, age, home address, phone number, marital status, income, credit history, medical information, education, employment information and social registration numbers.</p>
    
    <p>We are committed to providing our clients, customers, members ("you", "your" or "them") with exceptional service. Providing exceptional service may involve the collection, use and, at times, the disclosure of your Personal Information. Protecting your Personal Information is one of our highest priorities. While we have always respected your privacy and safeguarded all Personal Information, we have strengthened our commitment to this goal. This is to continue to provide exceptional service to you and to comply with all laws regarding the collection, use and disclosure of Personal Information. We will inform you of why and how we collect, use and disclose Personal Information; obtain your consent, as required; and handle Personal Information according to applicable law. Our privacy commitment includes ensuring the accuracy, confidentiality, and security of your Personal Information and allowing you to request access to, and correction of, your personal information. </p>
    
    <p>This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service.</p>
    
    <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>
    
    <p>We will only collect Personal Information that is necessary to fulfill the following purposes:</p>
    
    <ul>
        <li>To verify identity;</li>
        <li>To identify your preferences;</li>
        <li>To open and manage an account;</li>
        <li>To ensure you receive a high standard of service;</li>
        <li>To meet regulatory requirements;</li>
        <li>Other legal reasons as apply to the goods and services requested.</li>
    </ul>
    
    <p>We will obtain your consent to collect, use or disclose Personal Information. In some cases, we can do so without your consent (see below). You can provide consent orally, in writing, electronically or through an authorized representative.</p>
    
    <p>You provide implied consent where our purpose for collecting using or disclosing your Personal Information would be considered obvious or reasonable in the circumstances. Consent may also be implied where you have notice and a reasonable opportunity to opt-out of having your Personal Information used for mail-outs, marketing or fundraising and you do not opt-out.</p>
    
    <p>We may collect, use or disclose Personal Information without the consent:</p>
    
    <ul>
        <li>When permitted or required by law;</li>
        <li>In an emergency that threatens an individual's life, health, or personal security;</li>
        <li>When the Personal Information is available from a public source;</li>
        <li>When we require legal advice from a lawyer;</li>
        <li>For the purposes of collecting a debt or protection from fraud;</li>
        <li>Other legally established reasons.</li>
    </ul>
    
    <p>We will not sell your Personal Information to other parties unless consent has been provided or implied. We retain your Personal Information for the time necessary to fulfill the identified purposes or a legal or business purpose. We will make reasonable efforts to ensure that your Personal Information is accurate and complete. You may request correction to your Personal Information to ensure its accuracy and completeness. A request must be in writing and provide sufficient detail to identify your Personal Information and the correction being sought.</p>
    
    <p>We are committed to ensuring the security of your Personal Information and may use passwords, encryption, firewalls, restricted employee access or other methods, in our discretion. We will use appropriate security measures when destroying your Personal Information such as shredding documents or deleting electronically stored information, in our discretion.</p>
    
    <p><strong>Log Data</strong></p>
    
    <p>We may collect information that your web browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
    
    <p>In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this type of information in order to increase our Service's functionality. These third party service providers have their own privacy policies addressing how they use such information.</p>
    
    
    
    <p><strong>Cookies</strong></p>
    
    <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>
    
    <p>We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. If you do not instruct your browser to refuse all cookies or to indicate when a cookie is being sent, your consent to our use of your Personal Information may be implied.</p>
    
    
    
    <p><strong>Service Providers</strong></p>
    
    <p>We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
    
    <p>These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
    
    <p><strong>Communications</strong></p>
    
    <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>
    
    <p><strong>Compliance With Laws</strong></p>
    
    <p>As set out above, and for clarity, we will disclose your Personal Information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.</p>
    
    <p><strong>Business Transaction</strong></p>
    
    <p>If LunareSoft IT Consulting Services Inc. is involved in a merger, acquisition or asset sale, your Personal Information may be transferred. We will make reasonable efforts to provide notice before your Personal Information is transferred and becomes subject to a different Privacy Policy.</p>
    
    <p><strong>Security</strong></p>
    
    <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
    
    <p><strong>International Transfer</strong></p>
    
    <p>Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
    
    <p>If you are located outside Canada and choose to provide information to us, please note that we transfer the information, including Personal Information, to Canada and process it there.</p>
    
    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
    
    <p><strong>Links To Other Sites</strong></p>
    
    <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
    
    <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
    
    <p><strong>Children's Privacy</strong></p>
    
    <p>Our Service does not address anyone under the age of 18 ("Minor").</p>
    
    <p>We do not knowingly collect personally identifiable information from Minors. If you are a parent or guardian and you are aware that your Minor has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from Minors without verification of parental consent, we take steps to remove that information from our servers.</p>
    
    <p><strong>Changes To This Privacy Policy</strong></p>
    
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
    
    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    
    <p>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>
    
    <p><strong>Contact Us</strong></p>
    
    <p>If you have any questions about this Privacy Policy, please contact us.</p>    
    `,

}
