import React from 'react'
import texts from './UserAccountTexts'
import gStyles from '../../App.module.css';
import styles from './UserAccount.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import { goBack } from '../../navigation/PageManager'
import * as UserManager from '../../userManager/UserManager'
import {State as UserState, Errors} from './UserState'
import { Utils } from '../../util/Utils';
import {Notification} from '../../components/notification/Notification'

export default class UserAccount extends React.Component {

    constructor(props) {
        super(props);
        const userEmail = UserManager.getLoginUser().email;
        const userState = new UserState();
        userState.fields.email = userEmail;

        this.state = userState;

        this.PASSWORD_PATTERN    = /^[A-Za-z0-9.?!@#$%^&*_-]{3,100}$/; // letters, numbers, symbols .?!@#$%^&*_- between 3-100 length

    }

    onBack = () => {
        goBack()
    }

    onInputPassword = (e) => {
        this.setState( {fields: {...this.state.fields, password: e.currentTarget.value}} )
    }

    onChangePassword = () => {
        if (this.validatePassword() ) {
            try {
                UserManager.changePassword(this.state.fields.email, this.state.fields.password);
                this.setState( {fields: {...this.state.fields, password: ''}} )
                Notification.snackBarInfo(texts.changePasswordMsg);
            } catch (error) {
                Notification.snackBarError(texts.changePasswordError, 3);                
            }
        } else {
            Notification.snackBarError(texts.changePasswordError, 3);
        }
    }


    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{texts.title}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')} >

                    {/* email */}
                    <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                        <div className={gStyles.fieldLabel}>{texts.emailLabel}</div>
                        <input type='text' className={gStyles.inputField} readOnly={true}
                            value={this.state.fields.email} autoComplete='off'>
                        </input>
                    </div>

                    {/* password */}
                    <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                        <div className={gStyles.fieldLabel}>{texts.passwordLabel}</div>
                        <input type='password' placeholder={texts.passwordHint} className={gStyles.inputField}
                            value={this.state.fields.password} name={'password'} onChange={this.onInputPassword} autoComplete='off'>
                        </input>
                        <div className={this.state.errors.password ? gStyles.errorField : gStyles.hide} >{this.state.errors.password}</div>
                    </div>

                    <div className={[styles.commandButton].join(' ')} onClick={this.onChangePassword}>
                        <span className={[gStyles.btnDefault].join(' ')}>{texts.changePassword}</span>
                    </div>

                </main>
            </>


        )
    }

    /**
     * @returns {boolean} 
     */
    validatePassword = () => {
        const fieldErrors = new Errors();      
        let isPass = true;  
        // todo: write common validation util for password (duplicated in SignIn and UserAccount pages)
        if (Utils.isEmpty(this.state.fields.password)) {
            isPass = false;
            fieldErrors.password = texts.emptyPasswordError
        } else {
            if (!this.PASSWORD_PATTERN.test(this.state.fields.password)) {
                isPass = false;
                fieldErrors.password = texts.invalidPasswordError
            }        
        }        

        this.setState({errors: fieldErrors})
        return isPass;

    }    

}