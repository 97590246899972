export default {

    title               : 'Sign In',
    emailLabel          : 'Email:',
    passwordLabel       : 'Password:',
    
    emailHint           : 'yourname@company.com',
    passwordHint        : '',
    submit              : 'Sign In',

    emptyEmailError     : "Oops, you forgot to input your email",
    emptyPasswordError  : "No password, no entry",
    invalidEmailError   : "That's not a valid email, please try again",
    invalidPasswordError: "Hint: try longer password, you can use letters, numbers or symbols",
    wrongPasswordError  : "Tsk-tsk, that's not the correct password for this email",

    termsConditions     : 'By signing in, you agree to the terms and conditions and privacy policy of MyBrainMate.',

    signInErrorMsg      : 'Sign in error',
    signInMsg           : 'Signing in...',

}
