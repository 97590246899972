import React from 'react';
import {HomeHeader} from './HomeHeader';
import {HomeView, Apps, Sections} from './HomeView';
import * as PageManager from '../../navigation/PageManager';
import * as page from '../../util/PageConstants';
import { goToPage } from '../../navigation/PageManager';
import { Utils } from '../../util/Utils';
import { Expanded } from './HomeState';
import {SignIn} from '../signIn/SignIn'
import {UserMenu, UserOptions} from '../../components/userMenu/UserMenu'
import pStyles from './Home.module.css'
import * as UserManager from '../../userManager/UserManager'
import {Notification} from '../../components/notification/Notification'
import texts from './HomeTexts';


/**
 * Home Page
 * - container component
 */
export default class Home extends React.Component {

    constructor(props) {
        super(props);

        const user = UserManager.getLoginUser()
        const userEmail = user ? user.email : '';
        this.state = {
            expanded: new Expanded(),
            isShowLogin: false,
            isShowUserMenu: false,
            loginEmail: userEmail,
        }

    }


    componentDidMount() {
        // log ('Home did mount');
    }
    
    render() {
        return (
            <>
            <HomeHeader 
                onClickUser     = {this.onClickUser}
                loginEmail       = {this.state.loginEmail}
            /> 

            {/* Sign In */}
            <div className={pStyles.signInContainer}>
                {this.state.isShowLogin &&
                    <SignIn onSignIn={this.onSignIn} />
                }
            </div>

            {/* User Menu */}
            {this.state.isShowUserMenu &&
                <UserMenu 
                    loginEmail = {this.state.loginEmail}
                    onSelectUserMenu = {this.onSelectUserMenu}
                />
            }

            <HomeView
                expanded            = {this.state.expanded}
                onClickTask         = {this.onClickTask}
                onClickInfo         = {this.onClickInfo}
                onClickJournal      = {this.onClickJournal}
                onClickAndroidApps  = {this.onClickAndroidApps}
                onClickTerms        = {this.onClickTerms}
                onClickPrivacy      = {this.onClickPrivacy}
                onToggleSection     = {this.onToggleSection}
                onToggleApp         = {this.onToggleApp}
            />
            </>
        )
    }

    onClickUser = () => {
        if (Utils.isEmpty(this.state.loginEmail) ) {
            this.setState({isShowLogin: true});
        } else {
            this.setState({isShowUserMenu: true});  
        }
    }

    /**@param {string} email */
    onSignIn = (email) => {
        this.setState({isShowLogin: false, loginEmail: email});
    }

    onSignOut = () => {
        UserManager.signOut();
        this.setState({loginEmail: ''});
        Notification.snackBarInfo(texts.signOutMsg);
    }

    /**@param {string} option */
    onSelectUserMenu = (option) => {
        this.setState({isShowUserMenu: false});
        if (option === UserOptions.SIGNOUT) {
            this.onSignOut();
        }
        if (option === UserOptions.PROFILE) {
            goToPage(page.PROFILE)
        }
    }

    /**
     * @param {string} app
     */
    gotoAppOrig = (app) => {
        if (Utils.isNotEmpty(this.state.loginEmail)) {
            window.location.href = app;
            return;
        }
        this.setState({isShowLogin: true});  // login first before using an app
    }

    /**
     * @param {string} app
     */
    gotoApp = (app) => {
      // no login for now, just go to the app
      window.location.href = app;
    }
        
    
    onClickTask = () => {
        this.gotoApp(page.TASK_APP);
    }
    onClickInfo = () => {
        this.gotoApp(page.INFO_APP);
    }
    onClickJournal = () => {
      this.gotoApp(page.JOURNAL_APP);
  }
  onClickAndroidApps = () => {
        window.location.href = 'http://play.google.com/store/search?q=MyBrainMate&c=apps';  // go to Android Play store
    }
    onClickTerms = () => {
        goToPage(page.TERMS);
    }
    onClickPrivacy = () => {
        PageManager.goToPage(page.PRIVACY);
    }

    /**@param {string} section */
    onToggleSection = (section) => {
        switch (section) {
            case Sections.PRODUCTIVITY:
                this.setState( {expanded: {...this.state.expanded, productivity:!this.state.expanded.productivity} });
                break;
            case Sections.BRAIN:
                this.setState( {expanded: {...this.state.expanded, brain:!this.state.expanded.brain} });
                break;
            case Sections.STUDY:
                this.setState( {expanded: {...this.state.expanded, study:!this.state.expanded.study} });
                break;
            case Sections.MOBILE:
                this.setState( {expanded: {...this.state.expanded, mobile:!this.state.expanded.mobile} });
                break;
            default:
                break;
        }
    }        

    /**@param {string} app */
    onToggleApp = (app) => {
        Utils.debug('onToggleApp', app)
        switch (app) {
            case Apps.TASK:
                this.setState( {expanded: {...this.state.expanded, task:!this.state.expanded.task} });
                break;
            case Apps.INFO:
                this.setState( {expanded: {...this.state.expanded, info:!this.state.expanded.info} });
                break;
            case Apps.JOURNAL:
                this.setState( {expanded: {...this.state.expanded, journal:!this.state.expanded.journal} });
                break;
            case Apps.NOTE:
                this.setState( {expanded: {...this.state.expanded, note:!this.state.expanded.note} });
                break;
            case Apps.MONEY:
                this.setState( {expanded: {...this.state.expanded, money:!this.state.expanded.money} });
                break;
            case Apps.EVENT:
                this.setState( {expanded: {...this.state.expanded, event:!this.state.expanded.event} });
                break;
            default:
                break;
        }
    }    

}

