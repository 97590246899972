export default  {

    signIn              : 'Sign In',
    loginUser           : 'User',
    productivityHeader  : 'Productivity Tools',
    brainHeader         : 'Brain Tools',
    studyHeader         : 'Study Tools',
    mobileHeader        : 'Mobile Apps',

    // ********************************** Productivity Tools

    taskHeader          : 'MyBrainMate Task',
    taskDescription     : `
    <span style='font-weight:600'>MyBrainMate Task</span> is not your typical to-do or checklist. It’s a full-featured task management tool.
    `,
    taskMore            : `
    <span style='font-weight:600; font-size:18px'>
    Can you relate to the following scenarios?
    </span>
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>You're overwhelmed - you don't know where to start, everything is urgent!
    <li style='padding-bottom:10px'>You're busy but you feel no sense of accomplishment
    <li style='padding-bottom:10px'>You're late in your deliverables, you're focusing on wrong deliverables
    <li style='padding-bottom:10px'>You’re missing deadlines
    <li style='padding-bottom:10px'>You forget items for your shopping and groceries, you go back and waste time and money
    <li style='padding-bottom:10px'>You're stressed out!
    </ul>
    <p>
    <span style='font-weight:600'>MyBrainMate Task</span> is a Personal Information Management (PIM) software that helps address the issues above.
    <p>
    It's your digital organizer, assistant, secretary. Let it manage your day to day activities.
    <p style='font-weight:600; font-size:18px'>
    Features:
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>Multiple categories and sub-categories
    <li style='padding-bottom:10px'>Using Labels
    <li style='padding-bottom:10px'>Use of colors and images
    <li style='padding-bottom:10px'>Multiple views of your tasks
    <li style='padding-bottom:10px'>Repeating task
    <li style='padding-bottom:10px'>Reminders
    <li style='padding-bottom:10px'>Search
    <li style='padding-bottom:10px'>Filter
    <li style='padding-bottom:10px'>Auto-forward past due tasks
    <li style='padding-bottom:10px'>Vertical and horizontal scrolling
    <li style='padding-bottom:10px'>Expand and collapse groups    
    </ul>
    `,

    infoHeader          : 'MyBrainMate Info',
    infoDescription     : `<span style='font-weight:600'>MyBrainMate Info</span> is a tool to build your knowledge database. `,
    infoMore            : `
    Whether you're a student, professional or business owner, you have a specific set of knowledge you want to grow, keep, and maintain. This knowledge helps you gain the advantage in your chosen field.
    <p>
    <span style='font-weight:600'>MyBrainMate Info</span> helps you store and organize information that are important to you.
    <p style='font-weight:600; font-size:18px'>
    Usage
    <p>
    Memorizing is an expensive brain operation, so give your brain cells a break, outsource as many as you can to <span style='font-weight:600'>MyBrainMate Info</span>.
    <p>
    Storing information to <span style='font-weight:600'>MyBrainMate Info</span> is instant memorization without using your brain, it is immediate and more permanent. Another way of looking at it is - "store and forget"
    <p>
    Our brain has a fairly limited storage especially short-term memory. So you don't want to clutter your brain with information that you're not going to need immediately. You can store these information to <span style='font-weight:600'>MyBrainMate Info</span> and it will serve as an extension of your long-term memory.
    <p>
    You can then easily retrieve and recall your information by just a few taps from <span style='font-weight:600'>MyBrainMate Info</span>.
    <p style='font-weight:600; font-size:18px'>
    What to store
    <p>
    You can store information that is about you, relevant to you, used by you, experienced by you, or owned by you.
    <p>
    You can store different kinds of information such as favorites, lists, instructions, lessons in life, tips & tricks, health info, passwords, jokes, quotes, and many more.
    <p style='font-weight:600; font-size:18px'>
    Knowledge is Power
    <p>
    As they say, knowledge is power - only if you gather them.
    <p>
    The more you know, the more you can do.    
    `,

    journalHeader          : 'MyBrainMate Journal',
    journalDescription     : `<span style='font-weight:600'>MyBrainMate Journal</span>  is your tool to record special events in your life. `,
    journalMore            : `
    It helps you make sense out of your life experiences and find inspirations from the past.

    <p style='font-weight:600; font-size:18px'>
    Usage scenarios
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>Record life milestones
    <li style='padding-bottom:10px'>Learn from your mistakes
    <li style='padding-bottom:10px'>Emotional outlet
    <li style='padding-bottom:10px'>Personal diary
    <li style='padding-bottom:10px'>Travel logs
    <li style='padding-bottom:10px'>Health logs
    <li style='padding-bottom:10px'>Diet logs
    </ul>
    These are just some of the uses of <span style='font-weight:600'>MyBrainMate Journal</span>, you’re only limited by your imagination on how to use this tool.
    
    <p style='font-weight:600; font-size:18px'>
    Why use a journal
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>Be inspired and be thankful of your accomplishments
    <li style='padding-bottom:10px'>Time snapshots and journey back in time
    <li style='padding-bottom:10px'>For the simple pleasure of watching how your life unfolds
    <li style='padding-bottom:10px'>Share your life moments with people you love
    <li style='padding-bottom:10px'>Write to express yourself, your thoughts and feelings
    <li style='padding-bottom:10px'>For review purposes and retracing back your steps
    <li style='padding-bottom:10px'>Raw inputs for your autobiography
    <li style='padding-bottom:10px'>When no one wants to listen or you don’t want anyone to know
    <li style='padding-bottom:10px'>We look from our past to learn about our future
    `,

    moneyHeader          : 'MyBrainMate Money',
    moneyDescription     : `<span style='font-weight:600'>MyBrainMate Money</span> is a tool to help you manage your money and wealth. It's your personal finance management software.`,
    moneyMore            : `
    <span style='font-weight:600; font-size:18px'>
    Do you ever wonder what's your answers to the following questions?
    </span>
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>Does my income cover all my expenses?
    <li style='padding-bottom:10px'>How much cash do I have now?
    <li style='padding-bottom:10px'>Where does my money go?
    <li style='padding-bottom:10px'>Am I allocating enough money to important resources?
    <li style='padding-bottom:10px'>How much do I owe others?
    <li style='padding-bottom:10px'>How much others owe me?
    <li style='padding-bottom:10px'>What is my net worth?
    <li style='padding-bottom:10px'>Am I wealthy?
    <li style='padding-bottom:10px'>Do I have enough money during an emergency?
    <li style='padding-bottom:10px'>Can I survive without work for the next 3 months?
    </ul>
    <span style='font-weight:600'>MyBrainMate Money</span> can help you with the answers above.
    <p style='font-weight:600; font-size:18px'>
    What are the benefits of MyBrainMate Money?
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>It encourages you to save instead of spend
    <li style='padding-bottom:10px'>It helps you allocate money to more important items
    <li style='padding-bottom:10px'>It helps you get out of debt and stay debt-free
    <li style='padding-bottom:10px'>It tracks all your incomings (money in) and outgoings (money out)
    <li style='padding-bottom:10px'>It helps you to build and grow your wealth
    <li style='padding-bottom:10px'>It tracks your progress towards becoming financially secure
    </ul>
    <span style='font-weight:600'>MyBrainMate Money</span> can help you manage the following accounts:
    <ul style='padding-left:30px'>
    <li style='padding-bottom:10px'>Income
    <li style='padding-bottom:10px'>Expenses
    <li style='padding-bottom:10px'>Cash
    <li style='padding-bottom:10px'>Assets
    <li style='padding-bottom:10px'>Liabilities
    <li style='padding-bottom:10px'>Savings
    <li style='padding-bottom:10px'>Investments
    </ul>
    And you can add more accounts according to your needs.
    `,

    timeHeader          : 'MyBrainMate Time',
    timeDescription     : `<span style='font-weight:600'>MyBrainMate Time</span> is a tool to help you manage your time and schedule.`,
    timeMore            : `
    Usage and features... 
    `,

    ideaHeader          : 'MyBrainMate Idea',
    ideaDescription     : `<span style='font-weight:600'>MyBrainMate Idea</span> is a creativity tool to help you generate new and innovative ideas.`,
    ideaMore            : `
    Usage and features... 
    `,

    // ************************** Brain Tools

    choiceHeader          : 'MyBrainMate Choice',
    choiceDescription     : `<span style='font-weight:600'>MyBrainMate Choice</span> is a tool to help you choose wisely when presented with options.`,
    choiceMore            : `
    Usage and features... 
    `,

    decideHeader          : 'MyBrainMate Decide',
    decideDescription     : `<span style='font-weight:600'>MyBrainMate Decide</span> is a decision tool to guide you when making difficult decisions. `,
    decideMore            : `
    Usage and features... 
    `,

    logicHeader          : 'MyBrainMate Logic',
    logicDescription     : `<span style='font-weight:600'>MyBrainMate Logic</span> is an evaluation tool if you're reasoning is correct or simply logical.`,
    logicMore            : `
    Usage and features... 
    `,

    // ************************* Study tools

    mindMapHeader          : 'MyBrainMate MindMap',
    mindMapDescription     : `<span style='font-weight:600'>MyBrainMate MindMap</span> is an organization tool to help you learn complex topics by way of associations.`,
    mindMapMore            : `
    Usage and features... 
    `,

    memorizeHeader          : 'MyBrainMate Memorize',
    memorizeDescription     : `<span style='font-weight:600'>MyBrainMate Memorize</span> is learning tool to help you memorize information effectively`,
    memorizeMore            : `
    Usage and features... 
    `,

    mathHeader          : 'MyBrainMate Math',
    mathDescription     : `<span style='font-weight:600'>MyBrainMate Math</span> is a math tool to help you solve number problems.`,
    mathMore            : `
    Usage and features... 
    `,

    testHeader          : 'MyBrainMate Test',
    testDescription     : `<span style='font-weight:600'>MyBrainMate test</span> is a mock exam tool to help you prepare before taking a course exam.`,
    testMore            : `
    Usage and features... 
    `,

    // ************************* Mobile Apps

    androidStoreHeader          : 'MyBrainMate in Google Play',
    androidStoreDescription     : `If you prefer the native experience of mobile app, you can find MyBrainMate at Google Play.`,

    more            : 'Learn more...',
    less            : 'Show less',
    comingSoon      : 'Coming Soon!',

    terms         : 'Terms',
    privacy       : 'Privacy',

    signOutMsg      : 'Signing out...',
}