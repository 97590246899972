import { StateManager } from '../stateManager/StateManager';
import * as PageStore from './PageStore';
import * as PageConstants from '../util/PageConstants';

// **************** navigation

/**@type {string[]} */
export let callStack = [];    // store pages call stack, TODO: move to PageStore and replace that state


/**
 * 
 * @param {string} toPage 
 * @param {string} [fromPage]
 */
export const goToPage = (toPage, fromPage) => {
    if (toPage === PageConstants.HOME) {
        callStack = [PageConstants.HOME];  // reset callstack when navigating home
    } else {
        callStack.push(toPage);

    }
    PageStore.goToPage(toPage, fromPage);
}

export const goBack = () => {
    callStack.pop();
    PageStore.goBack();
}

/** handle browser back button */
export const browserBack = () => {
    if (callStack.length === 1) {  // home page
        window.history.back();  // execute browser back button
    } else {
        window.history.pushState({}, '', PageConstants.ROOT_APP);  //  add again the application to browser history for the next back button
        goBack();   // execute application back
    }
}

/**
 * 
 * @param {string} toPage 
 * @param {string} [fromPage]
 * @param {string} [taskId]  - pass task id to edit or empty to create
 */
export const goToTaskPage = (toPage, fromPage, taskId) => {
    PageStore.setTaskId(taskId);
    // PageStore.goToPage(toPage, fromPage);
    goToPage(toPage, fromPage);
}

/**
 * 
 * @param {string} toPage 
 * @param {string} [fromPage]
 * @param {string} [categoryId]  - pass task id to edit or empty to create
 */
export const goToCategoryPage = (toPage, fromPage, categoryId) => {
    PageStore.setCategoryId(categoryId);
    // PageStore.goToPage(toPage, fromPage);
    goToPage(toPage, fromPage);
}

export const getCurrentPage = () => {
    return PageStore.getCurrentPage();
}

// ************** page parameters

export const getTaskId = () => {
    return StateManager.getData().page.taskId;
}

/**@param {string} id */
export const setTaskId = (id) => {
    PageStore.setTaskId(id);
}

export const clearTaskId = () => {
    PageStore.setTaskId('');
}

export const getCategoryId = () => {
    return StateManager.getData().page.categoryId;
}

/**@param {string} id */
export const setCategoryId = (id) => {
    PageStore.setCategoryId(id);
}

export const clearCategoryId = () => {
    PageStore.setCategoryId('');
}

export const getLabelId = () => {
    return StateManager.getData().page.labelId;
}

/**@param {string} id */
export const setLabelId = (id) => {
    PageStore.setLabelId(id);
}

export const clearLabelId = () => {
    PageStore.setLabelId('');
}

export const getImageId = () => {
    return StateManager.getData().page.imageId;
}
/**@param {string} id */
export const setImageId = (id) => {
    PageStore.setImageId(id);
}

export const clearImageId = () => {
    PageStore.setImageId('');
}


