
import { Utils } from '../util/Utils';
import * as Constants from '../util/TaskConstants'

import { User } from './User';
import { UserActivity } from './UserActivity';
import {hash} from '../security/SecurityManager'

/**
 * @param {string} email
 * @returns {boolean} 
 */
export const isUserExist = (email) => {
    return Utils.isNotEmpty(findUser(email));
}

/**
 * @param {string} email
 * @returns {User} */
export const findUser = (email) => {
    try {
        if (Utils.isEmpty(email)) return null;
        const users_raw = localStorage.getItem(Constants.USERS_KEY);
        if (Utils.isEmpty(users_raw)) return null;

        /**@type {User[]} */
        const users = JSON.parse(users_raw);
        const user = users.find(u=>u.email===email);
        return user;

    } catch (error) {
        Utils.error('Error in finding user! ', error.message);
        return null;
    }
}

/**@returns {User} */
export const getLoginUser = () => {
    try {
        const userRaw = localStorage.getItem(Constants.LOGIN_USER_KEY);
        if (Utils.isEmpty(userRaw)) return null;

        /**@type {UserActivity} */
        const userActivity = JSON.parse(userRaw);
        /**@type {User} */
        const user = findUser(userActivity.email);
        return user;

    } catch (error) {
        Utils.error('Error in getting logged in user! ', error.message);
        return null;
    }
}

/**
 * @param {string} email 
 * @param {string} password 
 */
export const processUserCredentials = (email, password) => {
    try {
        let isExistingUser = true;
        const users_raw = localStorage.getItem(Constants.USERS_KEY);

        if (Utils.isEmpty(users_raw)) {
            isExistingUser = false;
        } else {
            /**@type {User[]} */
            const users = JSON.parse(users_raw);
            const user = users.find(u=>u.email===email);
            if (Utils.isEmpty(user)) {
                isExistingUser = false;
            }
        }

        if (isExistingUser) {  
            signIn(email);
        } else {
            signUp(email, password);
        }

    } catch (error) {
        Utils.error('Error in processing user credentials! ', error.message);
        throw error;
    }
}

/**
 * @param {string} email 
 */
const signIn = (email) => {
    Utils.debug('signing in user', email);
    try {
        const userActivity = new UserActivity();
        userActivity.email = email;
        userActivity.lastActivity = (new Date()).toString();
        localStorage.setItem(Constants.LOGIN_USER_KEY, JSON.stringify(userActivity));
        setToken(email);
    } catch (error) {
        Utils.error('Error in signing in user! ', error.message);
        throw error;  // rethrow
        
    }
}

/**
 * set cookie upon login for other subdomains to verify login
 * @param {string} email
 */
const setToken = (email) => {
        const token = hash(email);
        const days = 60*60*24*365; // 1 year
        document.cookie = `token=${token};domain=.mybrainmate.com;max-age=${days};path=/`;
}

const deleteToken = () => {
    document.cookie = `token=;domain=.mybrainmate.com;max-age=-1;path=/`;
}

/**
 * @param {string} email 
 * @param {string} password 
 */
const signUp = (email, password) => {
    Utils.debug('signing up user', email);
    try {
        const user = new User();
        user.email = email;
        user.password = hash(password);  
        const users_raw = localStorage.getItem(Constants.USERS_KEY);
        let users = [];
        if (Utils.isEmpty(users_raw)) {
            users = [user];
        } else {
            users = JSON.parse(users_raw);
            users = [...users, user];
        }
        localStorage.setItem(Constants.USERS_KEY, JSON.stringify(users));

        // automatically sign in user after sign up
        signIn(email)
            
    } catch (error) {
        Utils.error('Error in signing up user! ', error.message);
        throw error;  // rethrow
        
    }
}

export const signOut = () => {
    Utils.debug('signing out current user');
    try {
        localStorage.removeItem(Constants.LOGIN_USER_KEY);
        deleteToken();
    } catch (error) {
        Utils.error('Error in signing out user! ', error.message);
        throw error;  // rethrow        
    }

}

/**
 * @param {User} user 
 * @param {string} newPassword 
 */
export const changePassword = (user, newPassword) => {
    Utils.debug('changing password for user', user);
    try {
        user.password = hash(newPassword);  

        const users_raw = localStorage.getItem(Constants.USERS_KEY);
        /**@type {User[]} */
        let allUsers = JSON.parse(users_raw);

        const idx = allUsers.findIndex( (u) => {
            return user.email === u.email
        });
        if (idx >= 0) {  
            allUsers.splice(idx,1, user); // delete and insert
        } else {
            throw Error('Cannot change password, user email does not exist: ' + user.email)
        }
    
        localStorage.setItem(Constants.USERS_KEY, JSON.stringify(allUsers));

    } catch (error) {
        Utils.error('Error in changing password! ', error.message);
        throw error;  // rethrow
        
    }
}

/**
 * adapted from :  https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 * 
 * @param {string} storageType can also pass 'sessionStorage'
 * 
 */
export const isStorageAvailable = (storageType='localStorage') => {
    let storage;
    try {
        storage = window[storageType];
        let x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}