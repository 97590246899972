export default {

    profile      : 'Account',
    signOut       : 'Sign Out',
    byDate          : 'View By Date',
    byPriority      : 'View By Priority',
    byImportance    : 'View By Importance',
    byStatus        : 'View By Status',
    byLabel         : 'View By Label',

}
