
export class Expanded {
    // sections
    
    /**@type {boolean} */ productivity = true;
    /**@type {boolean} */ brain = true;
    /**@type {boolean} */ study = true;
    /**@type {boolean} */ mobile = true;

    // apps

    /**@type {boolean} */ task = false;
    /**@type {boolean} */ info = false;
    /**@type {boolean} */ journal = false;
    /**@type {boolean} */ note = false;
    /**@type {boolean} */ event = false;
    /**@type {boolean} */ money = false;
    /**@type {boolean} */ idea = false;

}


