import React from 'react';
import gTexts from '../../resources/texts'
import texts from './HomeTexts';
import gStyles from '../../App.module.css';
import styles from './Home.module.css';
import '../../resources/sprites.css';

import * as icon from '../../util/IconConstants'

import taskLogo from '../../resources/mybrainmate-task-logo.png'
import infoLogo from '../../resources/mybrainmate-info-logo.png'
import journalLogo from '../../resources/mybrainmate-journal-logo.png'
import moneyLogo from '../../resources/mybrainmate-money-logo.png'
import ideaLogo from '../../resources/mybrainmate-idea-logo.png'

export const Sections = {
    PRODUCTIVITY: 'PRODUCTIVITY',
    BRAIN: 'BRAIN',
    STUDY: 'STUDY',
    MOBILE: 'MOBILE',
}

export const Apps = {
    TASK: 'TASK',
    INFO: 'INFO',
    JOURNAL: 'JOURNAL',
    NOTE: 'NOTE',
    MONEY: 'MONEY',
    EVENT: 'EVENT',
    IDEA: 'IDEA',
}

// Home Page View - must be stateless

/**
 * @typedef {Object} Props
 * @property { import('./HomeState').Expanded } expanded
 * @property { ()=>void} onClickTask
 * @property { ()=>void} onClickInfo
 * @property { ()=>void} onClickJournal
 * @property { ()=>void} onClickAndroidApps
 * @property { ()=>void} onClickTerms
 * @property { ()=>void} onClickPrivacy
 * @property { (app:string)=>void} onToggleApp
 * @property { (section:string)=>void} onToggleSection
 * 
 */



/**@param {Props} props */
export const HomeView = (props) => (
    <main className={[gStyles.colFlex, styles.main].join(' ')}>

        {/* dummy line so the next line will style properly ? */}
        <hr className={styles.lineRule} style={{visibility:'hidden'}}></hr>


        {/* productivity section */}
        <div
            onClick={()=>props.onToggleSection(Sections.PRODUCTIVITY)} 
            className={[gStyles.rowFlex, styles.sectionHeader].join(' ')}>
            <span>{texts.productivityHeader}</span>
            <button className={[props.expanded.productivity ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </div>
        <hr className={styles.lineRule}></hr>

<div style={{ display: props.expanded.productivity ? 'block' : 'none' }}>

        {/* task */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex} style={{cursor:'pointer'}} onClick={props.onClickTask}>
                <img src={taskLogo} alt="task logo" className={styles.logo}></img>
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.taskHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.taskDescription }}
            />
            {/* more */}
            <div
                onClick={() => props.onToggleApp(Apps.TASK)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')} 
                style={{ display: props.expanded.task ? 'none' : 'block' }}
            >{texts.more}</div>

            <div style={{ display: props.expanded.task ? 'block' : 'none' }}>
                <div
                    className={styles.appDesc}
                    dangerouslySetInnerHTML={{ __html: texts.taskMore }}
                />
                <div 
                    onClick={() => props.onToggleApp(Apps.TASK)} 
                    className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')}
                >{texts.less}</div>

            </div>

        </div>

        {/* info */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}  style={{cursor:'pointer'}} onClick={props.onClickInfo}>
                <img src={infoLogo} alt="info logo" className={styles.logo}></img>
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.infoHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.infoDescription }}
            />
            {/* more */}
            <div
                onClick={() => props.onToggleApp(Apps.INFO)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')} 
                style={{ display: props.expanded.info ? 'none' : 'block' }}
            >{texts.more}</div>

            <div style={{ display: props.expanded.info ? 'block' : 'none' }}>
                <div
                    className={styles.appDesc}
                    dangerouslySetInnerHTML={{ __html: texts.infoMore }}
                />
                <div 
                    onClick={() => props.onToggleApp(Apps.INFO)} 
                    className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')}
                >{texts.less}</div>

            </div>

        </div>

        {/* journal */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex} style={{cursor:'pointer'}} onClick={props.onClickJournal}>
                <img src={journalLogo} alt="journal logo" className={styles.logo}></img>
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.journalHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.journalDescription }}
            />
            {/* more */}
            <div
                onClick={() => props.onToggleApp(Apps.JOURNAL)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')} 
                style={{ display: props.expanded.journal ? 'none' : 'block' }}
            >{texts.more}</div>

            <div style={{ display: props.expanded.journal ? 'block' : 'none' }}>
                <div
                    className={styles.appDesc}
                    dangerouslySetInnerHTML={{ __html: texts.journalMore }}
                />
                <div 
                    onClick={() => props.onToggleApp(Apps.JOURNAL)} 
                    className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')}
                >{texts.less}</div>

            </div>

        </div>

        {/* money */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                <img src={moneyLogo} alt="money logo" className={styles.logo}></img>
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.moneyHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.moneyDescription }}
            />
            {/* more */}
            <div
                onClick={() => props.onToggleApp(Apps.MONEY)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')} 
                style={{ display: props.expanded.money ? 'none' : 'block' }}
            >{texts.more}</div>

            <div style={{ display: props.expanded.money ? 'block' : 'none' }}>
                <div
                    className={styles.appDesc}
                    dangerouslySetInnerHTML={{ __html: texts.moneyMore }}
                />
                <div 
                    onClick={() => props.onToggleApp(Apps.MONEY)} 
                    className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')}
                >{texts.less}</div>

            </div>

        </div>

        {/* time */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={timeLogo} alt="time logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.timeHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.timeDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>

            {/* more */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')} 
                style={{ display: props.expanded.event ? 'none' : 'none' }}   
            >{texts.more}</div>

            <div style={{ display: props.expanded.event ? 'block' : 'none' }}>
                <div
                    className={styles.appDesc}
                    dangerouslySetInnerHTML={{ __html: texts.timeMore }}
                />
                <div 
                    onClick={() => props.onToggleApp(Apps.EVENT)} 
                    className={[gStyles.btnText, gStyles.splitRight, styles.toggleText].join(' ')}
                >{texts.less}</div>

            </div>

        </div>        

</div>


        {/* brain tools section */}
        <div
            onClick={()=>props.onToggleSection(Sections.BRAIN)} 
            className={[gStyles.rowFlex, styles.sectionHeader].join(' ')}>
            <span>{texts.brainHeader}</span>
            <button className={[props.expanded.brain ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </div>
        <hr className={styles.lineRule}></hr>

    <div style={{ display: props.expanded.brain ? 'block' : 'none' }}>

        {/* idea */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                <img src={ideaLogo} alt="idea logo" className={styles.logo}></img>
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.ideaHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.ideaDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        

        {/* choice */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={choiceLogo} alt="choice logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.choiceHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.choiceDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        

        {/* decide */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={decideLogo} alt="decide logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.decideHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.decideDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        

        {/* logic */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={logicLogo} alt="logic logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.logicHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.logicDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        
    </div>

        {/* Study tools section */}
        <div
            onClick={()=>props.onToggleSection(Sections.STUDY)} 
            className={[gStyles.rowFlex, styles.sectionHeader].join(' ')}>
            <span>{texts.studyHeader}</span>
            <button className={[props.expanded.study ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </div>
        <hr className={styles.lineRule}></hr>

    <div style={{ display: props.expanded.study ? 'block' : 'none' }}>

        {/* Mind Map */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={mindMapLogo} alt="mind map logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.mindMapHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.mindMapDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        

        {/* memorize */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={memorizeLogo} alt="memorize logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.memorizeHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.memorizeDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        

        {/* test */}
        <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
            {/* logo, title */}
            <div className={gStyles.rowFlex}>
                {/* <img src={testLogo} alt="test logo" className={styles.logo}></img> */}
                <div
                    className={[gStyles.link, styles.appTitle].join(' ')}
                    dangerouslySetInnerHTML={{ __html: texts.testHeader }}
                />
            </div>
            {/* description */}
            <div
                className={styles.appDesc}
                dangerouslySetInnerHTML={{ __html: texts.testDescription }}
            />
            {/* soon */}
            <div
                onClick={() => props.onToggleApp(Apps.EVENT)} 
                className={[gStyles.btnText, gStyles.splitRight, styles.soonText].join(' ')} 
            >{texts.comingSoon}</div>
        </div>        
    </div>

        {/* Mobile apps section */}
        <div
            onClick={() => props.onToggleSection(Sections.MOBILE)}
            className={[gStyles.rowFlex, styles.sectionHeader].join(' ')}>
            <span>{texts.mobileHeader}</span>
            <button className={[props.expanded.mobile ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </div>
        <hr className={styles.lineRule}></hr>

        <div style={{ display: props.expanded.mobile ? 'block' : 'none' }}>

            {/* Google Play */}
            <div className={[gStyles.colFlex, styles.appSection].join(' ')}>
                {/* logo, title */}
                <div className={gStyles.rowFlex} onClick={props.onClickAndroidApps}>
                    <div
                        className={[gStyles.link, styles.appTitle].join(' ')}
                        dangerouslySetInnerHTML={{ __html: texts.androidStoreHeader }}
                    />
                </div>
                {/* description */}
                <div
                    className={styles.appDesc}
                    dangerouslySetInnerHTML={{ __html: texts.androidStoreDescription }}
                />
            </div>


        </div>

        {/* footer */}
        <div className={[gStyles.rowFlex, styles.footer].join(' ')}>
            <hr style={{ border: '.8px solid gray', width: '100%' }}></hr>
            <div
                onClick={props.onClickTerms}
                style={{ cursor: 'pointer' }}
            >{texts.terms}</div>
            <div
                onClick={props.onClickPrivacy}
                style={{ paddingLeft: '20px', cursor: 'pointer' }}
            >{texts.privacy}</div>
            <div className={gStyles.splitRight}>&copy; {gTexts.copyRight}</div>
        </div>

    </main>
);


