

export class Fields {
    /**@type {string} */ email = '';
    /**@type {string} */ password = '';
}

export class Errors {
    /**@type {string} */ email = '';
    /**@type {string} */ password = '';
}


/**
 * alias for the State object to use as type for the component
 */
export class State {
    /**@type {Fields}*/         fields = new Fields();
    /**@type {Errors}*/         errors = new Errors();
}

