export default {

    title               : 'User Account',

    emailLabel          : 'Email:',
    passwordLabel       : 'Password:',
    changePassword      : 'Change Pasword',

    passwordHint        : 'Enter new password',

    emptyPasswordError  : 'Please enter new password',
    invalidPasswordError: 'Password should be longer and can only contain letters, numbers, or the symbols .?!@#$%^&*_-',

    changePasswordError : 'Password Error',
    changePasswordMsg   : 'Saving password',

}
