import React from 'react'
import styles from './UserMenu.module.css'
import dStyles from '../dropDown/Dropdown.module.css'
import gStyles from '../../App.module.css'
import texts from './UserMenuTexts'
import { SelectOption } from '../dropDown/SelectProps'


/**
 * @typedef {Object} Props
 * @property {string} loginEmail
 * @property {(option:string)=>void} onSelectUserMenu
 * 
 * @typedef {Object} State
 */

/** @extends {React.Component<Props, State>} */
export class UserMenu extends React.Component {

    /**@param {Props} props */
    constructor(props) {
        super(props);

        /**@type {SelectOption[]} */
        this.allViews = this.initViews();

        this.userMenuRef = React.createRef(); // reference to User menu component to capture click outside

    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside, false);
    }

    onClickOutside = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.userMenuRef.current.contains(e.target)) {
            // click outside of menu, close
            this.onSelect();
        }
    }

    /**@param {string} option */
    onSelect = (option = '') => {
        this.props.onSelectUserMenu(option);
    }



    render() {

        return (
            <div ref={this.userMenuRef}>
                <div className={[gStyles.colFlex, styles.userMenu].join(' ')}>

                    {/* options */}
                    {this.allViews.map((option, i) => (
                        <div key={i} onClick={() => this.onSelect(option.code)}
                            className={[
                                dStyles.selectOption,
                                this.props.loginEmail === option.code ? dStyles.selectOptionSelected : '',
                            ].join(' ')}>
                            {option.description}
                        </div>
                    ))
                    }

                </div>
            </div>
        )
    }

    initViews = () => {
        return [
            new SelectOption(UserOptions.PROFILE, this.props.loginEmail),
            new SelectOption(UserOptions.SIGNOUT, texts.signOut),
        ]
    }

}

export const UserOptions = {
    PROFILE: 'PROFILE',
    SIGNOUT: 'SIGNOUT',
}



