import React from 'react';
import { StateManager } from '../../stateManager/StateManager';
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants';

import * as MediaQueryManager from '../../navigation/MediaQueryManager'
import * as DataManager from '../../data/PersistenceManager'
import texts from '../../resources/texts'

// pages
import Home from '../home/Home';
import UserAccount from '../userAccount/UserAccount'
import Terms from '../terms/Terms'
import Privacy from '../privacy/Privacy'

import gStyles from '../../App.module.css'

import { browserBack } from '../../navigation/PageManager';
import { Utils } from '../../util/Utils';
import {Snackbar} from '../../components/notification/Snackbar'
import { Notification } from '../../components/notification/Notification';

/** 
 * the main page that renders the current page
 * 
 * listens to any state changes from Redux and propagate to children pages
 * 
 */
export default class Page extends React.Component {
    constructor(props) {
        super(props);

        if (!DataManager.isStorageAvailable()) {
            throw new Error(texts.localStorageError)
        }

        PageManager.goToPage(page.HOME);    // display the initial page

        this.dialogRef = React.createRef();  // reference for dialog box so it can receive focus when displayed
    }
    componentDidMount() {
        StateManager.store.subscribe(() => this.forceUpdate());  // re-render page when state changes
        MediaQueryManager.initMediaQueries();

        // capture browser back button
        window.history.pushState({}, '', page.ROOT_APP);
        window.addEventListener('popstate', browserBack, false);

        // global error handler
        window.onerror = (errMsg) => {
            console.error('MyBrainMate Site - Global Error: ', errMsg);
            return true;  // suppress error?
        }
    }

    componentWillUnmount() {
        MediaQueryManager.cleanupMediaQueries();
        window.removeEventListener('popstate', browserBack, false);
    }

    componentDidUpdate() {
    }

    render() {
        const max = PageManager.callStack.length - 1;
        return (
            <>
                {   // mount only the last page, the rest in the callstack is hidden
                    PageManager.callStack.map((p, i) => (
                        <div key={i} className={i === max ? gStyles.show : gStyles.hide}>
                            {this.getPageComponent(p)}
                        </div>
                    ))
                }

                {/* snackbar shows up only when there's a message */}
                <div style={{display:Utils.isEmpty(Notification.getSnackBarMessage()) ? 'none' : 'block'}}>
                    <Snackbar msg={Notification.getSnackBarMessage()}/>
                </div>



            </>
        )
    }


    /**@param {string} pageName */
    getPageComponent = (pageName) => {
        switch (pageName) {
            case page.HOME          : return <Home />
            case page.PROFILE       : return <UserAccount />
            case page.TERMS         : return <Terms />
            case page.PRIVACY       : return <Privacy />
            default                 : return <Home />
        }
    }
}