import React from 'react'
import styles from './SignIn.module.css'
import gStyles from '../../App.module.css'
import {Utils} from '../../util/Utils'
import texts from './SignInTexts'
import {State as SignInState, Errors} from './SignInState'
import * as DataManager from '../../data/PersistenceManager'
import {isCorrectPassword} from '../../security/SecurityManager'
import {Notification} from '../../components/notification/Notification'


/**
 * @typedef {Object} Props
 * @property {(email)=>void} onSignIn
 * 
 * @typedef {SignInState} State
 */

 /** @extends {React.Component<Props, State>} */
export class SignIn extends React.Component {

    /**@param {Props} props */
    constructor(props) {
        super(props);

        // initialize state
        /**@type {State} */
        this.state = new SignInState();

        this.signinRef = React.createRef(); // reference to signin component to capture click outside

        this.EMAIL_PATTERN       = /^.+@.+\..+$/; // <blah>@<blah>.<blah>
        this.PASSWORD_PATTERN    = /^[A-Za-z0-9.?!@#$%^&*_-]{3,100}$/; // letters, numbers, symbols .?!@#$%^&*_- between 3-100 length

        this.isUserExist = true;

    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside, false); 
    }


    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside, false); 
    }



    render() {

        return (
            <div ref={this.signinRef}>
                <div className={[gStyles.colFlex, styles.signin].join(' ')}>

                    {/* title */}
                    <div onClick={()=>this.onClose(null)} 
                        className={[
                            gStyles.rowFlex, 
                            styles.signinHeader
                            ].join(' ')}>
                        <span  style={{width:'90%'}}>{texts.title}</span>
                        <span className={gStyles.splitRight}>&times;</span>
                    </div>

                    {/* content */}
                    <div className={[gStyles.colFlex, styles.signinContent].join(' ')}>


                        {/* email */}
                        <div className={gStyles.formField}>
                            <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                                <span>{texts.emailLabel}</span>
                                <input type='email' className={gStyles.inputField} placeholder={texts.emailHint}
                                    value={this.state.fields.email} name={'email'} onChange={this.onInputEmail} autoComplete='off'>
                                </input>
                            </div>
                            <div className={this.state.errors.email ? gStyles.errorField : gStyles.hide} >{this.state.errors.email}</div>
                        </div>

                        {/* password */}
                        <div className={gStyles.formField}>
                            <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                                <span>{texts.passwordLabel}</span>
                                <input type='password' className={gStyles.inputField} 
                                    value={this.state.fields.password} name={'password'} onChange={this.onInputPassword} autoComplete='off'>
                                </input>
                            </div>
                            <div className={this.state.errors.password ? gStyles.errorField : gStyles.hide} >{this.state.errors.password}</div>
                        </div>

                        <div className={styles.termsConditions}>
                            {texts.termsConditions}
                        </div>

                    </div>

                    {/* action */}
                    <div className={[gStyles.rowFlex, styles.actionFooter].join(' ')}>
                        <div className={gStyles.splitRight}></div>

                        <div className={gStyles.btnText} style={{ marginLeft: '10px' }} onClick={this.onSubmit}>{texts.submit}</div>
                    </div>
                

                </div>

            </div>
        )
    }

    onClickOutside = (e) => {
        e.stopPropagation();
        if (!this.signinRef.current.contains(e.target)) {  
            // click outside of calendar, close
            this.onClose(null);
        }
    }

    onInputEmail = (e) => {
        this.setState( {fields: {...this.state.fields, email: e.currentTarget.value}} )
    }


    onInputPassword = (e) => {
        this.setState( {fields: {...this.state.fields, password: e.currentTarget.value}} )
    }

    /**@param {string} email */
    onClose = (email) => {
        this.props.onSignIn(email);
    }


    onSubmit = () => {
        const isPass = this.validate();
        if (isPass) {
            try {
                DataManager.processUserCredentials(this.state.fields.email, this.state.fields.password);
                this.onClose(this.state.fields.email);                    
                Notification.snackBarInfo(texts.signInMsg);
            } catch (error) {
                Notification.snackBarError(texts.signInErrorMsg);
            }

        } else {
            Notification.snackBarError(texts.signInErrorMsg);
        }
    }

    /**@returns {boolean} */
    validate = () => {

        this.isUserExist = DataManager.isUserExist(this.state.fields.email); 

        if (this.isUserExist) {
            return this.validateSignin()
        } else {
            return this.validateSignup();
        }

    }

    /**
     * @returns {boolean} 
     */
    validateSignin = () => {
        const fieldErrors = new Errors();      
        let isPass = true;  
        if (Utils.isEmpty(this.state.fields.email)) {
            isPass = false;
            fieldErrors.email = texts.emptyEmailError
        } else {
            // check if email exist
        }
        if (Utils.isEmpty(this.state.fields.password)) {
            isPass = false;
            fieldErrors.password = texts.emptyPasswordError
        } else {
            if (!isCorrectPassword(this.state.fields.email, this.state.fields.password)) {
                isPass = false;
                fieldErrors.password = texts.wrongPasswordError
            }
        }        

        this.setState({errors: fieldErrors})
        return isPass;

    }

    /**
     * @returns {boolean} 
     */
    validateSignup = () => {
        const fieldErrors = new Errors();      
        let isPass = true;  
        if (Utils.isEmpty(this.state.fields.email)) {
            isPass = false;
            fieldErrors.email = texts.emptyEmailError
        } else {
            if (!this.EMAIL_PATTERN.test(this.state.fields.email)) {
                isPass = false;
                fieldErrors.email = texts.invalidEmailError
            }        
        }
        if (Utils.isEmpty(this.state.fields.password)) {
            isPass = false;
            fieldErrors.password = texts.emptyPasswordError
        } else {
            if (!this.PASSWORD_PATTERN.test(this.state.fields.password)) {
                isPass = false;
                fieldErrors.password = texts.invalidPasswordError
            }        
        }        

        this.setState({errors: fieldErrors})
        return isPass;

    }

    submit = () =>{

    }

}



