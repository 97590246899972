import { Utils } from "../util/Utils"
import {findUser} from '../data/PersistenceManager'

/**
 * 
 * @param {string} src 
 * @returns {string}
 */
export const hash = (src) => {

    if (Utils.isEmpty(src)) return '';

    const rnd = Math.floor(Math.random() * 9) + 1; // 1 to 9
    let hash = '';

    const max = src.length;
    const timeStamp = new Date().getTime();
    for (let i=0; i<max; i++) {
        let code = src.charCodeAt(i) + i + rnd;
        hash += String.fromCharCode(code);
    }
    hash = ''.concat(hash,rnd+'','@',timeStamp+'');
    return btoa(hash);  // will be encrypted in https
}


/**
 * 
 * @param {string} src 
 * @returns {string}
 */
export const unhash = (src) => {

    if (Utils.isEmpty(src)) return '';

    src = atob(src);

    const max = src.lastIndexOf('@')-1;
    const rnd = parseInt(src[max]);
    let orig = '';

    for (let i=0; i<max; i++) {
        let code = src.charCodeAt(i) - i - rnd;
        orig += String.fromCharCode(code);
    }
    return orig;
}

/**
 * @param {string} email
 * @param {string} pwd
 * @returns {boolean}
 */
export const isCorrectPassword = (email, pwd) => {
    const user = findUser(email);
    if (Utils.isEmpty(user)) return false;

    const orig = unhash(user.password);
    return (pwd === orig);
}