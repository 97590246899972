import React from 'react'
import texts from './PrivacyTexts'
import gStyles from '../../App.module.css';
import styles from './Privacy.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import { goBack } from '../../navigation/PageManager'

export default class Terms extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{texts.title}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')}
                    dangerouslySetInnerHTML={{__html: texts.privacyPolicy }}
                >
                </main>
            </>




        )
    }

}