import React from 'react';
import gStyles from '../../App.module.css';
import pStyles from './Home.module.css'
import '../../resources/sprites.css';
import * as icon from '../../util/IconConstants'
import * as size from '../../util/SizeConstants'
import gTexts from '../../resources/texts';
import texts from './HomeTexts'

// Home Page Header - must be stateless


/**
 * @typedef {Object} Props
 * @property { ()=>void} onClickUser
 * @property {string} loginEmail
 */

/**@param {Props} props */

export const HomeHeader = (props) => (
    <>
        <nav className={[gStyles.rowNowrapFlex, gStyles.navBar].join(' ')} >

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{gTexts.appTitle}</span>
            
            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >

              {/* no login for now */}
              <div style={{display: 'none'}}>
                {/* not signed in */}
                {!props.loginEmail &&
                <div className={[gStyles.rowNowrapFlex, pStyles.signInLink].join(' ')} onClick={props.onClickUser}>
                    <span                         
                        style={{ paddingRight: size.XSMALL_SPACER }}
                        title={gTexts.userTip}>
                        <button className={icon.USER + " " + gStyles.btnIcon}></button>
                    </span>
                    <span style={{ paddingRight: size.NORMAL_SPACER }}> {texts.signIn} </span>
                    </div>
                }

                {/* signed in */}
                {props.loginEmail &&
                    <div className={[gStyles.rowNowrapFlex, pStyles.signInLink].join(' ')} onClick={props.onClickUser}>
                        <span                             
                            style={{ paddingRight: size.XSMALL_SPACER }}
                            title={props.loginEmail}>
                            <button className={icon.USER_LOGIN + " " + gStyles.btnIcon}></button>
                        </span>
                        <span style={{ paddingRight: size.NORMAL_SPACER }}>{texts.loginUser}</span>
                        {/* <span 
                            style={{ paddingRight: size.NORMAL_SPACER }}>
                            <button className={icon.DROPDOWN + " " + gStyles.btnIcon}></button>
                        </span> */}
                    </div>
                }
              </div>
            </div>
        </nav>


    </>
);


