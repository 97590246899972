/**
 * page ids used for navigation
 */

export const HOME               = 'HOME';

export const PROFILE            = 'PROFILE';
export const TERMS              = 'TERMS';
export const PRIVACY            = 'PRIVACY';

// redirect to apps url
export const ROOT_APP           = '/';  // the path of this app
export const TASK_APP           = 'https://task.mybrainmate.com';
export const INFO_APP           = 'https://info.mybrainmate.com';
export const JOURNAL_APP        = 'https://journal.mybrainmate.com';


