import * as DataManager from '../data/PersistenceManager'
import { User } from '../data/User';

User.toString();  // dummy call for JS Doc typing

/**@returns {User} */
export const getLoginUser = () => {
    return DataManager.getLoginUser();
}

// i stop here - delegate signin/signup here; send email

/**
 * @param {string} email
 * @returns {User} */
export const findUser = (email) => {
    return DataManager.findUser(email);
}

export const signOut = () => {
    DataManager.signOut();
}

/**
 * 
 * @param {string} email 
 * @param {string} newPassword
 */
export const changePassword = (email, newPassword) => {
    const user = findUser(email);
    DataManager.changePassword(user, newPassword)
}